html,
body {
  height: 100%;
  box-sizing: border-box;
}

*,
*::after,
*::before {
  box-sizing: inherit;
  padding: 0;
  margin: 0;
}
