@tailwind base;
@tailwind components;
@tailwind utilities;

#root {
  height: 100%;
}

.intro-window {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}
.intro-container {
  width: 193px;
  height: 23px;
  border: 2px solid #b2b2b2;
  border-radius: 7px;
  margin: 0 auto;
  padding: 2px 1px;
  overflow: hidden;
  font-size: 0;
}
.intro-box {
  width: 9px;
  height: 100%;
  background: linear-gradient(
    to bottom,
    #2838c7 0%,
    #5979ef 17%,
    #869ef3 32%,
    #869ef3 45%,
    #5979ef 59%,
    #2838c7 100%
  );
  display: inline-block;
  margin-right: 2px;
  animation: loader 2s infinite steps(30); /* Adjusted duration and steps */
}
.intro-logo {
  width: 220px;
  margin: 50px auto;
}
.intro-logo p {
  margin: 0;
  padding: 0;
}
.intro-top {
  font-size: 16px;
  font-weight: 300;
  line-height: 16px;
}
.intro-top:after {
  content: '\00a9';
  font-size: 10px;
  position: relative;
  top: -5px;
  margin-left: 2px;
}
.intro-mid {
  font-size: 46px;
  font-weight: 700;
  line-height: 36px;
}
.intro-mid span {
  font-size: 22px;
  display: inline-block;
  vertical-align: top;
  color: #ff6821;
  margin-top: -8px;
}
.intro-logo .intro-bottom {
  font-size: 30px;
  font-weight: 300;
  line-height: 30px;
  margin-left: 5px;
}
@keyframes loader {
  0% {
    transform: translate(-30px);
  }
  100% {
    transform: translate(190px);
  }
}
